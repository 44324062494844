import React from 'react'
import useMultiUiBrandContext from '../../contexts/MultiUiBrand/useMultiUiBrandContext'
import { UiTheme } from '../../config/constants'
import { ThemeProvider as VThemeProvider } from '@veneer/theme'
import { extendSemantics } from '@veneer/semantics'
import primitives from '@veneer/primitives'

const hpxTokens = {
  royalBlue0: '#f5f6ff',
  royalBlue1: '#e5eaff',
  royalBlue2: '#d6ddff',
  royalBlue3: '#c4caff', //different from veneer
  royalBlue4: '#9ba6ff', //different from veneer
  royalBlue5: '#8190ff',
  royalBlue6: '#6374fd',
  royalBlue7: '#4759f5',
  royalBlue8: '#3543c0',
  royalBlue9: '#15218f', //different from veneer
  royalBlue10: '#28307b',
  royalBlue11: '#262b65',
  royalBlue12: '#242852',
  background: '#edeefe'
}

const hpxTheme = extendSemantics({
  color: {
    light: {
      background: {
        disabled: hpxTokens.royalBlue3, // disabled background
        default: hpxTokens.background
      },
      foreground: {
        disabled: primitives.color.white // also controls secondary button border and font color
        // disabled: hpxTokens.royalBlue4 // gets correct disabled secondary button, but cannot see text as well in disabled primary button
      },
      primary: {
        foreground: {
          default: primitives.color.white // primary text
        },
        base: {
          default: hpxTokens.royalBlue7, // primary background
          hover: hpxTokens.royalBlue8, // primary hover + secondary border
          active: hpxTokens.royalBlue9 // primary click
        },
        background: {
          default: primitives.color.red10,
          hover: hpxTokens.royalBlue1, //
          active: primitives.color.royalBlue3 // secondary click
        }
      }
    }
  }
})

export const getThemeProviderConfig = (uiBrand = UiTheme.hpsmart) => {
  const config = {
    customSemantics: undefined,
    mode: 'light',
    shape: 'round',
    density: 'standard',
    inputBackground: undefined
  }

  if (uiBrand === UiTheme.hpx) {
    config.customSemantics = hpxTheme
    config.shape = 'pill'
  }

  return config
}

const ThemeProvider = ({ children }) => {
  const { uiBrand } = useMultiUiBrandContext()
  const props = getThemeProviderConfig(uiBrand)

  return <VThemeProvider {...props}>{children}</VThemeProvider>
}

export default ThemeProvider
